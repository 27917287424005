<template>
    <div><img src="../assets/img/event.jpg"></div>
</template>

<script>
// import { gameData, gameType, gameSystem, demoUrl } from '../components/tool/GameConfig'; // eslint-disable-line
export default {
    name: 'Event',
    components: {
    },
    data() {
        return {
            detail: {}
        }
    },
    created() {
        this.initData()
    },
    computed: {
    },
    methods: {
    }
}
</script>

<style lang="less" scoped>
  img {
    width: 100%;
  }
</style>